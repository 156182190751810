.background {
  background-color: white;
  min-height: calc(100vh - 50px);
  position: relative;
  padding-bottom: 20px;
}

.triangleBottom {
  position: absolute;
  bottom: 0px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.triangleTop {
  position: absolute;
  top: 0px;
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.box {
  position: relative;
  padding-top: 100px;
  z-index: 3;
  display: inline-table;
  grid-column-start: 1;
  color: white;
  font-family: 'Barlow Condensed', sans-serif;
}

.box > .header {
  background-color: #0246ce;
  font-size: 75px;
  font-weight: bold;
  letter-spacing: 3px;
  width: calc(50vw - 350px);
  margin-left: 20px;
  z-index: 2;
  position: relative;
  text-align: center;
  padding: 10px 75px;
  border-radius: 5px;
}

.box > .body {
  background-color: #eb0018;
  font-size: 35px;
  margin-top: -80px;
  margin-left: 50px;
  padding-top: 85px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  width: calc(50vw - 200px);
  border-radius: 5px;
  letter-spacing: 3px;
  height: 300px;
}

.box > .body > div:last-child {
  margin-top: 30px;
}

.bodySmaller {
  font-size: 25px;
  padding-left: 20px;
  letter-spacing: 3px;
}

.icon {
  width: calc(33% - 60px);
  display: inline;
  margin-top: 15px;
  cursor: pointer;
  vertical-align: top;
  margin-left: 50px;
}

.icon:hover {
  width: calc(33% - 50px);
  margin-top: 10px;
  margin-left: 45px;
  margin-right: -5px;
}

.venmo {
  width: calc(33% - 60px);
  display: inline;
  margin-top: 15px;
  vertical-align: top;
  margin-left: 50px;
}

@media only screen and (max-width: 1500px) and (min-width: 1130px) {
  .icon {
    width: calc(50% - 60px);
  }

  .icon:hover {
    width: calc(50% - 50px);
  }

  .venmo {
    width: calc(50% - 60px);
  }

  .box > .body {
    height: auto;
    min-height: 300px;
  }
}

@media only screen and (max-width: 1130px) {
  .box > .header {
    font-size: 10vw;
  }

  .box > .body {
    margin-top: -12vw;
    font-size: 8vw;
    padding-top: 12vw;
  }

  .box > .body > div {
    display: inline-block;
    vertical-align: top;
    width: 50%;
  }

  .background {
    height: fit-content;
  }

  .box {
    display: block;
    margin-left: 0px !important;
    padding-top: 50px;
  }

  .box > .header {
    width: calc(100vw - 240px);
  }

  .box > .body {
    width: calc(100vw - 130px);
    height: auto;
  }

  .box > .body > div:last-child {
    margin-top: 0px;
  }

  .box:last-child {
    margin-bottom: 50px;
  }

  .triangleBottom {
    bottom: -50px;
  }
}

@media only screen and (max-width: 800px) {
  .icon {
    width: calc(50% - 60px);
  }

  .icon:hover {
    width: calc(50% - 50px);
  }

  .venmo {
    width: calc(50% - 60px);
  }
}

@media only screen and (max-width: 762px) {
  .box > .body > div {
    display: block;
    width: 100%;
  }

  .box > .body > div:last-child {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 550px) {
  .icon {
    width: calc(50% - 20px);
    margin-left: 15px;
  }

  .icon:hover {
    width: calc(50% - 10px);
    margin-left: 10px;
  }

  .venmo {
    width: calc(70% - 20px);
    margin-left: 50px;
  }
}
