.background {
  background-color: #0246ce;
  height: calc(100vh - 50px);
  position: relative;
  overflow: hidden;
}

.box {
  background-color: #eb0018;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  padding: 20px;
  height: 370px;
}

label {
  font-family: 'Barlow Condensed', sans-serif;
  color: white;
  font-size: 60px;
}

input {
  display: block;
  width: 800px;
  height: 50px;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  font-size: 30px;
  margin-bottom: 10px;
} 

.buttonContainer {
  margin-top: 10px;
  position: absolute;
  left: calc(100% - 85px);
  transform: translate(-50%, 0);
  display: inline;
}

.forgot {
  display: inline;
  font-family: 'Barlow Condensed', sans-serif;
  color: white;
  font-size: 20px;
  font-style: italic;
  position: relative;
  top: 25px;
  cursor: pointer;
}

.forgot:hover {
  text-decoration: underline;
}

.toast {
  z-index: 5;
}

@media only screen and (max-width: 910px) {
  .box {
    width: calc(100% - 80px);
    height: 330px;
  }

  input {
    width: calc(100% - 30px);
  }

  label {
    font-size: 40px;
  }
}