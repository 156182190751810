.button {
  background-color: #eb0018; 
  border-radius: 10px;
  color: white;
  font-size: 38px; 
  width: fit-content;
  padding: 5px 20px;
  white-space: nowrap;
  cursor: pointer;
}

.button:hover {
  margin-top: -1px;
  margin-bottom: -1px;
  font-size: 40px;
}