.background {
  background-color: #0246ce;
  min-height: calc(100vh - 50px);
  position: relative;
  display: flex;
  flex-direction: column;
}

.stripes { 
  position: absolute;
  display: inline;
  right: 150px;
  height: 100%;
  width: 300px;
}

.header {
  background-color: #eb0018;
  color: white;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 75px;
  font-weight: bold;
  letter-spacing: 3px;
  width: fit-content;
  padding: 20px 50px;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  z-index: 5;
  outline: 10px solid #0246ce;
}

.arrowLeft {
  height: calc(100% - 200px);
  width: 100px;
  position: absolute;
  top: 100px;
  left: 100px;
  cursor: pointer;
}

.arrowLeft:hover {
  height: calc(100% - 194px);
  width: 106px;
  top: 97px;
  left: 97px;
}

.arrowRight {
  height: calc(100% - 200px);
  width: 100px;
  position: absolute;
  top: 100px;
  right: 100px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  cursor: pointer;
}

.arrowRight:hover {
  height: calc(100% - 194px);
  width: 106px;
  top: 97px;
  right: 97px;
}

.arrowDescription {
  color: white;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 20px;
  position: absolute;
  z-index: 2;
  left: 50vw;
  top: 280px;
  -ms-transform:  translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
  display: none;
  font-style: italic;
}

.post {
  margin-top: 100px;
  width: calc(100vw - 500px); 
  margin-left: 250px;
  color: white;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 30px;
  font-weight: bold;
}

.imgContainer {
  text-align: center;
  width: 38vw;
}

.imgContainer > img {
  max-width: 33vw;
  max-height: calc(100vh - 450px);
  display: inline;
  z-index: 2;
  position: relative;
}

.text {
  position: absolute;
  top: 310px;
  left: calc(33vw + 350px);
  width: calc(67vw - 650px);
  padding: 10px;
  background-color: #0246ce;
  max-height: 790px;
  overflow-y: hidden;
  border: 1px solid white;
  border-radius: 3px;
}

.date {
  font-style: italic;
  width: fit-content;
}

.caption {
  width: fit-content;
}

.arrowSmall {
  overflow-y: hidden;
  margin-top: 25px;
  height: 100%;
}

.arrowSmall > img {
  position: absolute;
  display: none;
  height: 100%;
  width: 75px;
}

.arrowSmall > .left {
  left: 25px;
}

.arrowSmall > .right {
  right: 25px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.noInfo {
  color: white;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 30px;
  text-align: center;
  margin-top: 20px;
}

@media only screen and (max-width: 1300px) {
  .post {
    margin-top: 50px;
  }

  .imgContainer {
    width: calc(100vw - 500px);
  }

  .imgContainer > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100vw - 500px);
    max-height: 40vh;
  }

  .text {
    position: relative;
    top: 50px;
    left: 0px;
    width: 100%;
    font-size: 25px;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 950px) {
  .stripes {
    right: 10vw;
    width: 30vw;
  }
}

@media only screen and (max-width: 900px) {
  .arrowLeft {
    left: 50px;
  }

  .arrowLeft:hover {
    left: 47px;
  }

  .arrowRight {
    right: 50px;
  }

  .arrowRight:hover {
    right: 47px;
  }

  .post {
    margin-left: 200px;
    width: calc(100vw - 400px);
  }

  .post > img {
    max-width: calc(100vw - 400px);
  }

  .text {
    height: fit-content;
    max-height: none;
  }
}

@media only screen and (max-width: 725px) {
  .arrowLeft {
    width: 75px;
    height: calc(100% - 350px);
    top: 250px;
    left: 15px;
  }

  .arrowLeft:hover {
    top: 247px;
    left: 22px;
    width: 81px;
    height: calc(100% - 344px);
  }

  .arrowRight {
    width: 75px;
    height: calc(100% - 350px);
    top: 250px;
    right: 15px;
  }

  .arrowRight:hover {
    top: 247px;
    right: 22px;
    width: 81px;
    height: calc(100% - 344px);
  }

  .post {
    margin-left: 150px;
    width: calc(100vw - 300px);
  }

  .imgContainer {
    width: calc(100vw - 300px);
  }

  .imgContainer > img {
    max-width: calc(100vw - 300px);
  }
}

@media only screen and (max-width: 600px) {
  .arrowLeft {
    height: 80px;
    width: 60px;
  }

  .arrowLeft:hover {
    height: 85px;
    width: 65px;
  }

  .arrowRight {
    height: 80px;
    width: 60px;
  }

  .arrowRight:hover {
    height: 85px;
    width: 65px;
  }

  .post {
    margin-top: 110px;
    margin-left: 25px;
    width: calc(100vw - 50px);
  }

  .arrowSmall > img {
    display: inline;
    height: 300px;
  }

  .arrowDescription {
    display: inline;
  }

  .imgContainer {
    width: 100%;
  }

  .imgContainer > img {
    max-width: 100%;
  }

  .text {
    top: 0px;
  }
}

@media only screen and (max-width: 450px) {
  .header {
    font-size: 10vw;
  }

  .post {
    margin-top: 155px;
  }
}