.background {
  background-color: #0246ce;
  min-height: calc(100vh - 50px);
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.stripes { 
  position: absolute;
  display: inline;
  right: 150px;
  height: 100%;
  width: 300px;
}

.text {
  position: relative;
  margin-top: 100px;
  z-index: 3;
  border: 10px solid #0246ce;
  background-color: #0246ce;
  font-family: 'Barlow Condensed', sans-serif;
  display: inline;
} 

.header {
  background-color: #eb0018;
  color: white;
  font-size: 75px;
  font-weight: bold;
  letter-spacing: 3px;
  width: calc(100vw - 300px);
  margin-left: 20px;
  z-index: 2;
  position: relative;
  text-align: center;
  padding: 10px 75px;
  border-radius: 5px;
}

.body {
  background-color: white;
  color: #0246ce;
  font-size: 35px;
  margin-top: -80px;
  margin-left: 50px;
  padding-top: 75px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  width: calc(100vw - 120px);
  border-radius: 5px;
}

.links {
  /* flex-grow: 1; */
  height: fit-content;
}

.buttonContainer {
  outline: 10px solid #0246ce;
  display: inline;
  position: absolute;
  left: 75%;
  transform: translate(-50%, 0);
  margin-top: 100px;
}

.video {
  position: relative;
  width: calc(50vw - 50px);
  height: fit-content;
  min-height: 300px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 25px;
  margin-right: 25px;
}

@media only screen and (max-width: 1500px) {
  .background {
    height: fit-content;
  }

  .video {
    height: 400px;
    display: block !important;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    width: 70vw;
    max-height: 50vw;
  }

  .buttonContainer {
    display: block !important;
    position: relative;
    width: fit-content;
    left: 50vw;
    margin-bottom: 100px;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 950px) {
  .stripes {
    right: 10vw;
    width: 30vw;
  }
}

@media only screen and (max-width: 728px) {
  .header {
    font-size: 8vw;
    width: calc(100vw - 230px);
  }

  .body {
    margin-top: -8vw;
    padding-top: 8vw;
  }
}

@media only screen and (max-width: 600px) {
  .buttonContainer {
    font-size: 6vw !important;
  }
}

@media only screen and (max-width: 550px) {
  .body {
    font-size: 6vw;
  }
}
