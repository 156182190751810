.background {
  background-color: white;
  min-height: calc(100vh - 50px);
  position: relative;
  padding: 10px;
}

.triangleBottom {
  position: absolute;
  left: 0px;
  bottom: 0px;
}

.triangleTop {
  position: absolute;
  right: 0px;
  top: 0px;
  -webkit-transform: scaleX(-1) scaleY(-1);
  transform: scaleX(-1) scaleY(-1);
}

.text {
  font-family: 'Barlow Condensed', sans-serif;
  padding-top: calc(50vh - 500px);
}

.header {
  font-size: 75px;
  font-weight: bold;
  letter-spacing: 3px;
  text-align: center;
  z-index: 2;
  position: relative;
}

.body {
  font-size: 55px;
  padding-top: 50px;
  width: 920px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
  position: relative;
}

@media only screen and (max-width: 1000px) {
  .background {
    height: fit-content;
  }

  .text {
    padding-top: 20px;
  }

  .header {
    font-size: 50px;
  }

  .body {
    width: calc(100vw - 50px);
    margin-left: 25px;
    margin-bottom: 20px;
    padding-top: 30px;
    font-size: 30px;
  }

  .triangleBottom {
    bottom: -30px;
  }
}
