.background {
  background-color: #0246ce;
  min-height: calc(100vh - 100px);
  position: relative;
  overflow: hidden;
  padding: 25px;
}

.header {
  color: white;
  font-size: 55px;
  margin-bottom: 15px;
}

.filename {
  color: white;
  font-size: 20px;
  position: relative;
  top: -10px;
}

.newsletterDate {
  min-height: 20px;
  width: 250px;
}

textarea {
  width: 100%;
  border-radius: 5px;
  font-size: 20px;
  margin-bottom: 15px;
}

label {
  color: white;
  font-size: 35px;
  width: 150px;
  display: inline;
}

input {
  display: block;
  width: calc(100% - 25px);
  height: 40px;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  font-size: 30px;
  margin-bottom: 10px;
}

table {
  background-color: white;
  color: black;
  font-size: 20px;
  width: 100%;
  border-collapse: collapse;
  border-radius: 3px;
}

table th + th {
  border-left: 3px solid gray;
}

th {
  border-bottom: 3px solid black;
}

table th, table td{ /* Added padding for better layout after collapsing */
  padding: 4px 8px;
}

td > a {
  color: blue;
}

td > a:hover {
  text-decoration: underline;
  cursor: pointer;
}

.loadMorePosts {
  color: white;
  font-size: 20px;
  float: right;
  margin-top: 10px;
}

.loadMorePosts:hover {
  text-decoration: underline;
  cursor: pointer;
}