.header {
  background-color: #eb0018;
  height: 50px;
  position: sticky;
  top: 0px;
  z-index: 5;
}

.header > div {
  display: inline-block;
}

.header > img {
  width: 30px;
  float: right;
  margin-top: 6px;
  margin-right: 28px;
  cursor: pointer;
}

.header > img:hover {
  width: 32px;
  margin-right: 27px;
  margin-top: 5px;
}

.header > div > span {
  cursor: pointer;
  position: absolute;
  top: 0;
  transform: translate(-50%, 0);
  margin-top: 3px;
  color: white;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 35px;
  font-weight: 500;
  letter-spacing: 3px;
  text-align: center;
}

.header > div > span:hover {
  font-size: 40px;
  margin-top: 0.5px;
}

.home {
  left: calc(50vw - 600px);
}

.ourMission {
  left: calc(50vw - 300px);
}

.gettingStarted {
  left: 50vw;
}

.about {
  left: calc(50vw + 300px);
}

.newsfeed {
  left: calc(50vw + 600px);
}

.smallScreenIcon {
  float: left !important;
  height: 35px;
  width: 45px !important;
  margin-left: 28px;
  margin-top: 8px !important;
  display: none;
}

.smallScreenIcon:hover {
  height: 37px;
  width: 47px !important;
  margin-left: 27px;
  margin-top: 7px !important;
}

.dropdown {
  background-color: #760206;
  display: inline-block !important;
  padding: 10px;
  position: absolute;
  top: 50px;
  left: 0px;
  border-top: 3px solid black;
  border-radius: 0 0 10px 0;
  width: 300px;
  height: 230px;
}

.dropdown > span {
  display: block;
  position: static !important;
  text-align: left !important;
  transform: none !important;
}

@media only screen and (max-width: 1600px) {
  .home {
    left: calc(50vw - 500px);
  }
  
  .ourMission {
    left: calc(50vw - 250px);
  }

  .about {
    left: calc(50vw + 250px);
  }
  
  .newsfeed {
    left: calc(50vw + 500px);
  }
}

@media only screen and (max-width: 1350px) {
  .header > div {
    display: none;
  }

  .smallScreenIcon {
    display: block;
  }
}

.header > .homeAdmin {
  cursor: pointer;
  position: absolute;
  top: 0px;
  margin-top: 3px;
  margin-left: 10px;
  color: white;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 35px;
  font-weight: 500;
  letter-spacing: 3px;
  text-align: center;
}

.header > .homeAdmin:hover {
  font-size: 40px;
  margin-top: 0.5px;
  margin-left: 7.5px;
}
