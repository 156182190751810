.background {
  background-color: #0246ce;
  height: calc(100vh - 50px);
  position: relative;
  overflow: hidden;
}

.logo {
  height: calc(100% - 40px);
  margin-top: 20px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  z-index: 2;
}

.wraps {
  height: calc(100% - 50px);
  right: 0px;
  position: absolute;
}

@media only screen and (max-width: 1300px) {
  .logo {
    height: auto;
    margin-top: 0px;
    width: calc(100vw - 50px);
    top: calc(50vh - 35px);
    left: 25px;
    transform: translate(0, -50%);
  }

  .wraps {
    height: calc(100vh + 500px);
    right: 0px;
    bottom: 10px;
    transform: translate(50%, 0);
  }
}